import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';


const name = ["hello, i am", "naburan", "amirthalingam"];

const transition = {
    ease: [0.5, 0.01, -0.05, 0.9]
}

const animateTextVariant = {
    animate: i => ({
        transition: {
            delayChildren: 0.6,
            staggerChildren: 0.18,
            staggerDirection: i,//we can replace it with custom number so that custom props doesn't need to declared!
        }
    })
}

const animateLetterVariant = {
    initial: { opacity: 0, y: "120%" },
    animate: {
        opacity: 1, y: 0,
        transition: {
            duration: 1.4,
            ...transition,
        }
    },

}

const Header = () => (
    <div className="app__header app__flex">
        <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className="app__header-info"
        >
            <div className="app__header-badge">
                <div className="badge-profile">
                    {name.map((n, i) => <motion.h1
                        variants={animateTextVariant}
                        initial="initial"
                        animate="animate"
                        className="animateText"

                    >
                        {
                            n.split("").map((letter, index) => (
                                letter == " " ? <motion.p
                                    className='app__flex'
                                    key={index}
                                    variants={animateLetterVariant}
                                    custom={1} // we can remove it if we declare a staggerDirection of 1 or -1 !
                                    style={{ marginRight: "2vw" }}
                                >
                                    {letter}
                                </motion.p> :
                                    <motion.p
                                        key={index}
                                        variants={animateLetterVariant}
                                        custom={1} // we can remove it if we declare a staggerDirection of 1 or -1 !
                                    >
                                        {letter}
                                    </motion.p>
                            ))
                        }
                    </motion.h1>
                    )}
                </div>
            </div>
        </motion.div >

        <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, delayChildren: 0.5 }}
            className="app__header-img"
        >
            <motion.img
                src={images.profile}
                alt="profile_bg"
                whileInView={{
                    y: [0, -10],
                }}
                exit={{ opacity: 0 }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                    delay: 0.4,
                }}
            />

            {/* <motion.div
                className='profile-img app__flex'
                // whileInView={{ opacity: 1, x: 0 }}
                // initial={{ opacity: 0, x: 40 }}
                // exit={{ opacity: 0, x: -20 }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}



            >
                <motion.img
                    className='overlay'
                    src={images.profilePill}
                    alt="profile_bg"
                    style={{
                        width: '100%',
                        top: '10%',
                        bottom: 0,
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.4,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileStats}
                    alt="profile_stats"
                    style={{
                        width: '22%',
                        top: '10%',
                        left: '0',
                        bottom: '40%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.6
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileIphone}
                    alt="profile_iphone"
                    style={{
                        maxWidth: '68%',
                        top: 0,
                        left: '12%',
                        bottom: '10%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileBar}
                    alt="profile_bar"
                    style={{
                        maxWidth: '30%',
                        top: '16%',
                        left: '22%',
                        bottom: 0,
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.3,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileContact}
                    alt="profile_contact"
                    style={{
                        maxWidth: '34%',
                        top: '40%',
                        left: '20%',
                        bottom: '10%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.5,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileSearch}
                    alt="profile_search"
                    style={{
                        maxWidth: '34%',
                        top: '36%',
                        right: '16%',
                        bottom: 0,
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.7,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileHome}
                    alt="profile_home"
                    style={{
                        maxWidth: '16%',
                        top: 0,
                        right: '8%',
                        bottom: '16%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.9,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profilePlay}
                    alt="profile_play"
                    style={{
                        maxWidth: '16%',
                        top: '10%',
                        left: 0,
                        bottom: 0,
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.6,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileAcc}
                    alt="profile_acc"
                    style={{
                        maxWidth: '16%',
                        top: 0,
                        left: '18%',
                        bottom: '88%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.7,
                    }}
                />
                <motion.img
                    className='overlay'
                    src={images.profileImg}
                    alt="profile_img"
                    style={{
                        maxWidth: '50%',
                        top: 0,
                        left: '14%',
                        bottom: '30%',
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    transition={{
                        duration: 2.2,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: 0.2,
                    }}
                />
            </motion.div> */}
        </motion.div>
    </div >
);




export default AppWrap(Header, 'home');