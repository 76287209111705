import urlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'
import { client } from '../client'
import * as React from "react";
import { useState, useRef } from "react";
import { motion, useDomEvent } from "framer-motion";

const transition = {
    type: "spring",
    damping: 25,
    stiffness: 120
};

// Barebones lazy-loaded image component
const SampleImageComponent = ({ value, isInline }) => {
    const { width, height } = getImageDimensions(value);

    const [isOpen, setOpen] = useState(false);

    useDomEvent(useRef(window), "scroll", () => isOpen && setOpen(false));
    return (
        <div className={`image-container ${isOpen ? "open" : ""}`}>
            <motion.div
                animate={{ opacity: isOpen ? 1 : 0 }}
                transition={transition}
                className="shade"
                onClick={() => setOpen(false)}
            />
            <motion.img
                src={urlBuilder(client)
                    .image(value)
                    .url()}
                alt={value.alt || ' '}
                loading="lazy"
                style={{
                    // Display alongside text if image appears inside a block text span
                    display: isInline ? 'inline-block' : 'block',

                    // Avoid jumping around with aspect-ratio CSS property
                    aspectRatio: width / height,
                }}
                onClick={() => setOpen(!isOpen)}
                layout
                transition={transition}
            />
        </div>
    )
}

const PortableTextComponents = {
    types: {
        image: SampleImageComponent,
        // Any other custom types you have in your content
        // Examples: mapLocation, contactForm, code, featuredProjects, latestNews, etc.
    },
}

export default PortableTextComponents