import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import sass from '../assets/sass.png';

import profile from '../assets/profile.png';
import logo from '../assets/logo-n.svg';
import bgPill from '../assets/bg-pill.svg'

import profileAcc from '../assets/profile/profile-acc.png';
import profileBar from '../assets/profile/profile-bar.png';
import profileContact from '../assets/profile/profile-contact.png';
import profileHome from '../assets/profile/profile-home.png';
import profileIphone from '../assets/profile/profile-iphone.png';
import profileImg from '../assets/profile/profile-img.png';
import profilePill from '../assets/profile/profile-pill.png';
import profilePlay from '../assets/profile/profile-play.png';
import profileSearch from '../assets/profile/profile-search.png';
import profileStats from '../assets/profile/profile-stats.png';

import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import coffee from '../assets/coffee.png';


const exportedObject = {
    css,
    figma,
    flutter,
    git,
    graphql,
    html,
    javascript,
    node,
    react,
    sass,
    profile,
    logo,
    bgPill,
    profileAcc,
    profileBar,
    profileContact,
    profileHome,
    profileImg,
    profileIphone,
    profilePill,
    profilePlay,
    profileSearch,
    profileStats,
    email,
    mobile,
    coffee,
};

export default exportedObject;
