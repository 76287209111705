import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedin } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/naburan/">
        <FaLinkedin />
      </a>
    </div>
    <div>
      <BsInstagram href='' />
    </div>
  </div>
);

export default SocialMedia;
