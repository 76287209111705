import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion, AnimateSharedLayout } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { PortableText } from '@portabletext/react';
import { PortableTextComponents } from '../../components';

import './Work.scss';
import { FaDirections } from 'react-icons/fa';

const Work = () => {
    const [works, setWorks] = useState([]);
    const [filterWork, setFilterWork] = useState([]);
    const [activeFilter, setActiveFilter] = useState('All');
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

    useEffect(() => {
        const query = '*[_type == "works"]';

        client.fetch(query).then((data) => {
            setWorks(data);
            setFilterWork(data);
        });
    }, []);

    const handleWorkFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{ y: 100, opacity: 0 }]);

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }]);

            if (item === 'All') {
                setFilterWork(works);
            } else {
                setFilterWork(works.filter((work) => work.tags.includes(item)));
            }
        }, 500);
    };


    const [workOpen, setWorkOpen] = useState(false)
    const [activeWork, setActiveWork] = useState(null);

    const handleActiveWork = (index) => {
        setActiveWork(index);
        console.log(index);
        console.log(workOpen);
    }

    document.body.classList.toggle('no-scroll', workOpen);

    return (
        <>
            <h2 className="head-text">My Creative <span>Portfolio</span> Section</h2>

            <div className="app__work-filter">
                {['UI/UX', 'Web App', 'Mobile App', 'React JS', 'Flutter', 'All'].map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                    >
                        {item}
                    </div>
                ))}
            </div>

            <motion.div
                animate={animateCard}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="app__work-portfolio"
            >
                {filterWork.sort((a, b) =>
                    b.releaseDate.split('/').reverse().join().localeCompare(a.releaseDate.split('/').reverse().join())).map((work, index) => (

                        <AnimateSharedLayout>

                            <div className={workOpen && activeWork === index ? 'app__modal_blur' : ''} onClick={() => setWorkOpen(false)} />

                            <div className={`app__work-item ${workOpen && activeWork === index ? 'expanded' : 'app__flex'}`}
                                key={index}
                                layout
                                onClick={() => workOpen ? '' : (handleActiveWork(index), setWorkOpen(true))}
                            >
                                {workOpen && activeWork === index &&
                                    <>
                                        <span className='closeWork' onClick={() => setWorkOpen(false)} />
                                    </>

                                }
                                <div className={workOpen && activeWork === index ? "yes-scroll" : ""}>
                                    <div className="app__work-img app__flex">
                                        <img src={urlFor(work.imgUrl)} alt={work.name} />

                                        <motion.div
                                            layout
                                            whileHover={{ opacity: [0, 1] }}
                                            transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                                            className="app__work-hover app__flex"
                                            style={workOpen ? { display: 'none' } : ''}
                                        >
                                            <a href={work.projectLink} target="_blank" rel="noreferrer">

                                                <motion.div

                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.90] }}
                                                    transition={{ duration: 0.25 }}
                                                    className="app__flex"
                                                >
                                                    <AiFillEye />
                                                </motion.div>
                                            </a>
                                            <a href={work.codeLink} target="_blank" rel="noreferrer">
                                                <motion.div
                                                    whileInView={{ scale: [0, 1] }}
                                                    whileHover={{ scale: [1, 0.90] }}
                                                    transition={{ duration: 0.25 }}
                                                    className="app__flex"
                                                >
                                                    <AiFillGithub />
                                                </motion.div>
                                            </a>
                                        </motion.div>
                                    </div>

                                    <div className="app__work-content app__flex">
                                        <h4 className="bold-text">{work.title}</h4>
                                        <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>

                                        {
                                            workOpen && activeWork === index ?
                                                <ul
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-25px',
                                                    }}
                                                >
                                                    {(
                                                        work.tags.map((tag) => {
                                                            return (

                                                                <li className="app__work-tag app__flex" style={{
                                                                    position: 'static',
                                                                    display: 'inline-flex',
                                                                    marginRight: 10,
                                                                }}>
                                                                    <p className="p-text">{tag}</p>
                                                                </li>
                                                            )
                                                        }))}
                                                </ul>
                                                :
                                                (
                                                    < div className="app__work-tag app__flex">
                                                        <p className="p-text">{work.tags[0]}</p>
                                                    </div>
                                                )
                                        }

                                        {workOpen && activeWork === index &&

                                            <>
                                                <div className="blog-bg">
                                                    <PortableText value={work.content} components={PortableTextComponents} />
                                                </div>

                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </AnimateSharedLayout>

                    ))
                }
            </motion.div>





        </>
    );
};

export default AppWrap(
    MotionWrap(Work, 'app__works'),
    'work',
    'app__primarybg',
);