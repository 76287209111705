import React, { useEffect, useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

import { FaSun, FaMoon } from 'react-icons/fa';

const html = document.querySelector('html');
html.dataset.theme = `theme-light`;

function switchTheme(theme) {
    html.dataset.theme = `theme-${theme}`;
}



const Navbar = () => {
    const [toggleMenu, setToggle] = useState(false);

    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const storedPreference = localStorage.getItem('darkModePreference');
        if (storedPreference) {
            setDarkMode(JSON.parse(storedPreference));
        }
        else {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            setDarkMode(prefersDarkMode);
        }
    }, []);


    useEffect(() => {
        const toggleDark = document.querySelector('.toggle-inner')

        // If dark mode is enabled - adds classes to update dark-mode styling.
        // Else, removes and styling is as normal.
        if (darkMode === true) {
            switchTheme('dark');
            toggleDark.classList.add('toggle-active')
        } else {
            switchTheme('light');
            toggleDark.classList.remove('toggle-active')
        }
    }, [darkMode])
    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <a href='#'>
                    <img src={images.logo} alt="logo" />
                </a>
            </div>
            <ul className="app__navbar-links">
                {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                    <li className="app__flex p-text" key={`link-${item}`}>
                        <div />
                        <a href={`#${item}`}>{item}</a>
                    </li>
                ))}
            </ul>

            <div
                id="toggleDark"
                onClick={() => darkMode === false ? setDarkMode(true) : setDarkMode(false)}
            >
                <div className="toggle-inner">{darkMode == false ? <FaSun /> : <FaMoon />}</div>
            </div>

            <div className="app__navbar-menu">
                <HiMenuAlt4 onClick={() => setToggle(true)} />

                {toggleMenu && (
                    <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.45, ease: 'easeOut' }}
                    >
                        <HiX onClick={() => setToggle(false)} />
                        <ul>
                            {['home', 'about', 'work', 'skills', 'contact'].map((item) => (
                                <li key={item}>
                                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </div>
        </nav>
    )
}

export default Navbar